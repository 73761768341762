import React from "react"
import CardLoading from '../common/CardLoading'
import CardsAdd from "./CardsAdd"
import CardsDelete from "./CardsDelete"
import CardsIndex from "./CardsIndex"
import apiClient from "../api/api-client"
import Success from "../common/Success"
import Error from "../common/Error"

import "./upload.css"

const isASuccess = (res) =>
  res.status === 200 || res.status === 201 || res.status === 204
class Upload extends React.Component {
  state = {
    upload: false,
    cardAction: "index",
    cards: [],
    card: null,
    errorMessage: null,
    successMessage: null,
    loading: true,
  }

  componentDidMount = () => {
    this.retrieveCards()
  }

  retrieveCards = async () => {
    const cards = await apiClient.getCards()
    this.setState({ cards, loading: false })
  }

  addNewCard = async (formData) => {
    const response = await apiClient.addCard(formData)
    const isSuccess = isASuccess(response)
    this.setState({
      cardAction: isSuccess ? "success" : "error",
      card: isSuccess ? response.data : null,
      successMessage: isSuccess
        ? `Success! ${response.data.name} was created! Please refresh on next page`
        : null,
      errorMessage: isSuccess ? null : response.status,
    })
  }

  updateCard = async (id, formData) => {
    const response = await apiClient.updateCard(id, formData)
    const isSuccess = isASuccess(response)
    this.setState({
      cardAction: isSuccess ? "success" : "error",
      card: isSuccess ? response.data : null,
      successMessage: isSuccess
        ? `Success! ${response.data.name} was updated! Please refresh on next page`
        : null,
      errorMessage: isSuccess ? null : response.response.statusText,
    })
  }

  deleteCard = async (id) => {
    const response = await apiClient.deleteCard(id)
    const isSuccess = isASuccess(response)
    this.setState({
      cardAction: isSuccess ? "success" : "error",
      card: null,
      successMessage: isSuccess
        ? "Card was successfully deleted! Please refresh on next page"
        : null,
      errorMessage: isSuccess ? null : response.response.statusText,
    })
  }

  render() {
    const {
      card,
      cards,
      upload,
      cardAction,
      errorMessage,
      loading,
      successMessage,
    } = this.state
    if (upload) {
      switch (cardAction) {
        case "index":
          return (
            <CardsIndex
              onScreenChange={(val, card) =>
                this.setState({ cardAction: val, card })
              }
              retrieveCards={() => {
                this.setState({ loading: true })
                this.retrieveCards()
              }}
              cards={cards}
              loading={loading}
            />
          )
        case "add":
          return (
            <CardsAdd
              card={card}
              addNewCard={(data) => this.addNewCard(data)}
              updateCard={(id, data) => this.updateCard(id, data)}
              goBack={() => this.setState({ cardAction: "index", card: null })}
            />
          )

        case "delete":
          return (
            <CardsDelete
              onScreenChange={(val, card) =>
                this.setState({ cardAction: val, card })
              }
              deleteCard={(id) => this.deleteCard(id)}
              card={card}
            />
          )

        case "success":
          return (
            <Success
              text={successMessage}
              onClick={() => this.setState({ cardAction: "index", card: null })}
            />
          )

        case "error":
          const errorText = `Error! ${errorMessage}`
          return (
            <Error
              text={errorText}
              onClick={() => this.setState({ cardAction: "index", card: null })}
            />
          )

        default:
          return <CardLoading />
      }
    }
    return (
      <div className="upload-container">
        <div className="upload-message">
          Not Authenticated
          <span>
            <div onClick={() => this.setState({ upload: true })}>.</div>
          </span>
        </div>
      </div>
    )
  }
}

export default Upload

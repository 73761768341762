import React from "react"
import CardLoading from './common/CardLoading'
import About from "./About"
import Buy from "./Buy"
import Cards from "./cards"
import Upload from "./upload"
import Cart from "./cart"
import Menu from "./Menu"
import apiClient from "./api/api-client"
import { HEADER_COLOR } from './common/Constants'
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faShoppingCart,
  faInfoCircle,
  faCommentDollar,
  faBookOpen,
  faChevronLeft,
  faUpload,
  faCut
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faShoppingCart,
  faInfoCircle,
  faCommentDollar,
  faBookOpen,
  faChevronLeft,
  faUpload,
  faCut
)

class App extends React.Component {
  constructor(props) {
    super()
    this.state = {
      cart: [],
      cards: [],
      screen: 'home'
    }
  }
  retrieveCards = async () => {
    const cards = await apiClient.getCards()
    this.setState({ cards, loading: false })
  }

  componentDidMount = async () => {
    this.retrieveCards()
    document.body.style.margin = "0px"
  }

  handleCardClick = (id) => {
    const { cart } = this.state
    const idx = cart.indexOf(id)
    if (idx === -1) {
      this.setState({ cart: [...cart, id]})
    } else {
      let newCart = [...cart.slice(0, idx), ...cart.slice(idx + 1)]
      this.setState({ cart: newCart })
    }
  }

  renderScreen = () => {
    if (this.state.loading) return <CardLoading />

    switch (this.state.screen) {
      case 'buy':
        return (
          <Buy
            onCardClick={(id) => this.handleCardClick(id)}
            cart={this.state.cart}
            cards={this.state.cards}
            onClick={() => this.setState({ screen: 'home' })}
          />
        )
      case 'about':
        return (
          <About
            onClick={() => this.setState({ screen: 'home' })}
          />
        )
      case 'cart':
        return (
          <Cart
            onCardClick={(id) => this.handleCardClick(id)}
            cart={this.state.cart}
            cards={this.state.cards}
          />
        )
      case 'upload':
        return (
          <Upload />
        )
      default:
        return (
          <Cards
            onCardClick={(id) => this.handleCardClick(id)}
            cart={this.state.cart}
            cards={this.state.cards}
            retrieveCards={() => this.retrieveCards()}
          />
        )
    }
  }

  render() {
    return (
      <div>
        <div style={{ height: "20px", backgroundColor: HEADER_COLOR}}/>
        <Menu
          currentScreen={this.state.screen}
          onClick={(newScreen) => this.setState({ screen: newScreen })}
          cart={this.state.cart}
        />
        <div style={{ height: "20px", backgroundColor: HEADER_COLOR }} />
        {this.renderScreen()}
      </div>
    )
  }
}

export default App

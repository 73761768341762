import React from "react"
import PropTypes from "prop-types"
import { SCREENS } from "../common/Constants"

import "./cardsAdd.css"

const INPUTS = [
  { id: 1, name: "name", fieldName: "Name" },
  { id: 2, name: "info", fieldName: "Info" },
]

class CardsAdd extends React.Component {
  constructor(props) {
    super(props)
    const card = props.card ? props.card : {}
    const cats = props.card
      ? props.card.categories.map((item) => item.toUpperCase())
      : []
    this.state = {
      card: card,
      name: card.name,
      info: card.info,
      vertical: card.vertical,
      sold: card.sold,
      mainImage: card.main_image,
      categories: cats,
      selectedImage: card.image || null,
      imageFromRails: card.image ? true : false,
      file: null,
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const {
      card,
      selectedImage,
      name,
      info,
      vertical,
      sold,
      mainImage,
      imageFromRails,
      categories,
    } = this.state

    if (
      selectedImage === null ||
      name === null ||
      name === "" ||
      name === undefined
    )
      return

    const formCardData = new FormData()
    formCardData.append("name", name)
    formCardData.append("info", info)
    formCardData.append("vertical", vertical)
    formCardData.append("sold", sold)
    formCardData.append("main_image", mainImage)
    formCardData.append("categories", categories)

    if (selectedImage !== null && !imageFromRails) {
      formCardData.append("image", selectedImage)
    }

    if (card.id > 0) {
      this.props.updateCard(card.id, formCardData)
    } else {
      this.props.addNewCard(formCardData)
    }
  }

  renderCategories = () => {
    const { categories } = this.state
    return SCREENS.map((item) => {
      const bigName = item.screen.toUpperCase()
      const isChecked = categories.includes(bigName)
      return (
        <div key={item.id} className="checkboxes">
          <input
            type="checkbox"
            className="checkbox"
            id={bigName}
            name={bigName}
            checked={isChecked}
            onChange={() => {
              if (isChecked) {
                this.setState({
                  categories: categories.filter((cat) => cat !== bigName),
                })
              } else {
                this.setState({
                  categories: [...categories, bigName],
                })
              }
            }}
          />
          <label htmlFor={bigName}>{bigName}</label>
        </div>
      )
    })
  }

  renderTextInputs = () => {
    return INPUTS.map((item) => (
      <div key={item.id} className="cardadd-row">
        <label className="cardadd-label">{item.name.toUpperCase()}</label>
        <input
          name={item.fieldName}
          value={this.state[`${item.name}`]}
          onChange={(e) => this.setState({ [item.name]: e.target.value })}
          className="cardadd-text-input"
        />
      </div>
    ))
  }

  renderRadios = () => {
    return (
      <>
        <div className="cardadd-row">
          <div className="cardadd-radio-row">
            <label>
              <input
                type="radio"
                name="vertical"
                value="true"
                checked={this.state.vertical}
                onChange={() => this.setState({ vertical: true })}
                className="cardadd-radio"
              />
              Vertical
            </label>
            <label>
              <input
                type="radio"
                name="vertical"
                value="false"
                checked={!this.state.vertical}
                onChange={() => this.setState({ vertical: false })}
                className="cardadd-radio"
              />
              Horizontal
            </label>
          </div>
        </div>
        <div className="cardadd-row">
          <div className="cardadd-radio-row">
            <label>
              <input
                type="radio"
                name="sold"
                value="true"
                checked={this.state.sold}
                onChange={() => this.setState({ sold: true })}
                className="cardadd-radio"
              />
              Is Sold
            </label>
            <label>
              <input
                type="radio"
                name="sold"
                value="false"
                checked={!this.state.sold}
                onChange={() => this.setState({ sold: false })}
                className="cardadd-radio"
              />
              Is Not Sold
            </label>
          </div>
        </div>
        <div className="cardadd-row">
          <div className="cardadd-radio-row">
            <label>
              <input
                type="radio"
                name="mainImage"
                value="true"
                checked={this.state.mainImage}
                onChange={() => this.setState({ mainImage: true })}
                className="cardadd-radio"
              />
              Is Main Image
            </label>
            <label>
              <input
                type="radio"
                name="mainImage"
                value="false"
                checked={!this.state.mainImage}
                onChange={() => this.setState({ mainImage: false })}
                className="cardadd-radio"
              />
              Is Not Main Image
            </label>
          </div>
        </div>
      </>
    )
  }

  imageOrUploader = () => {
    const { selectedImage, name, imageFromRails, file } = this.state
    if (selectedImage !== null) {
      const src = imageFromRails ? selectedImage.url : file
      return (
        <div className="loaded-image-container">
          <img className="loaded-image" alt={name} src={src} />
          <div>
            <button
              onClick={() => {
                URL.revokeObjectURL(file)
                this.setState({ selectedImage: null, imageFromRails: false })
              }}
            >
              Remove Image
            </button>
          </div>
        </div>
      )
    }
    return (
      <div className="cardadd-img-drop">
        <input
          type="file"
          onChange={(e) =>
            this.setState({
              selectedImage: e.target.files[0],
              file: URL.createObjectURL(e.target.files[0]),
            })
          }
        />
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className="go-back-btn">
          <button onClick={() => this.props.goBack()}>Go Back</button>
        </div>
        <form className="cardadd-container" onSubmit={this.handleSubmit}>
          <p>You MUST provide an image</p>
          <p>You MUST provide a UNIQUE name</p>
          <div>{this.renderTextInputs()}</div>
          <div>{this.renderRadios()}</div>
          <div>{this.renderCategories()}</div>
          <div className="cardadd-row">{this.imageOrUploader()}</div>
          <button className="cardadd-form-btn">Send</button>
        </form>
      </div>
    )
  }
}

export default CardsAdd

CardsAdd.defaultProps = {
  id: null,
  name: "",
  info: "",
  vertical: true,
  sold: false,
  mainImage: false,
  categories: [],
  selectedImage: {
    name: "",
    byte_size: 0,
    url: "",
  },
}

CardsAdd.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  card: PropTypes.object,
  info: PropTypes.string,
  vertical: PropTypes.bool,
  sold: PropTypes.bool,
  mainImage: PropTypes.bool,
  categories: PropTypes.array,
  selectedImage: PropTypes.shape({
    name: PropTypes.string,
    byte_size: PropTypes.number,
    url: PropTypes.string,
  }),
}

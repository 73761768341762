import React from "react"
import CardLoading from '../common/CardLoading'
import Card from "../common/Card"
import Filters from "./Filters"

import "./cardsIndex.css"

class CardsIndex extends React.Component {
  state = {
    categoryFilters: [],
    searchVal: ""
  }
  filterCards = (cards) => {
    const { categoryFilters, searchVal } = this.state

    return cards.filter((card) => {
      const lowName = card.name ? card.name.toLowerCase() : ""
      const lowInfo = card.info ? card.info.toLowerCase() : ""
      if (!lowInfo.includes(searchVal) && !lowName.includes(searchVal)) {
        return false
      }

      let cardIsGood = true
      for (let index = 0; index < card.categories.length; index++) {
        for (let i = 0; i < categoryFilters.length; i++) {
          if (
            categoryFilters[i].toLowerCase() ===
            card.categories[index].toLowerCase()
          ) {
            cardIsGood = false
            continue
          }
        }
        if (!cardIsGood) continue
      }
      return cardIsGood
    })
  }

  mapCards = () => {
    const { cards } = this.props
    const filteredCards = this.filterCards(cards)
    return filteredCards.map((item) => (
      <div key={item.id} className="allcards-wrapper">
        <div onClick={() => this.props.onScreenChange("add", item)}>
          <Card alt={item.name} card={item} />
        </div>
        <button
          className="delete-card-btn"
          onClick={() => this.props.onScreenChange("delete", item)}
        >
          Delete Card
        </button>
      </div>
    ))
  }

  handleCategoryClick = (cat) => {
    const { categoryFilters } = this.state

    let newFilters = categoryFilters
    const idx = categoryFilters.indexOf(cat.toLowerCase())
    if (idx === -1) {
      newFilters.push(cat.toLowerCase())
    } else {
      newFilters = [...newFilters.slice(0, idx), ...newFilters.slice(idx + 1)]
    }
    this.setState({ categoryFilters: newFilters })
  }

  handleChange = (event) => {
    this.setState({ searchVal: event.target.value.toLowerCase() });
  }

  loaderOrCards = () => {
    if (this.props.loading) return <CardLoading />
    return (
      <div className="allcards-container">
        <div className="allcards-add-btn">
          <div>
            <button onClick={() => this.props.onScreenChange("add", null)}>
              Add New Card
            </button>
          </div>
          <div>
            <button onClick={() => this.props.retrieveCards()}>Refresh</button>
          </div>
        </div>
        <div className="filter-btns">
          <label style={{ paddingRight: '10px' }}>Search</label>
          <input
            type="text"
            value={this.state.searchVal}
            onChange={this.handleChange}
          />
        </div>
        <div className="filter-btns">
          <Filters
            categoryFilters={this.state.categoryFilters}
            categoryClicked={(cat) => this.handleCategoryClick(cat)}
          />
        </div>
        <div className="allcards-cardwrapper">{this.mapCards()}</div>
      </div>
    )
  }

  render() {
    return <div className="upload-container">{this.loaderOrCards()}</div>
  }
}

export default CardsIndex

import axios from "axios"

const url =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_DEV

const instance = axios.create({
  baseURL: url,
})

const apiClient = {
  getProcess: () => process.env,
  getCards: () =>
    instance
      .get("/cards")
      .then((response) => logAndReturn(response, true))
      .catch((err) => logAndReturn(err, false)),

  addCard: (data) =>
    instance
      .post("/cards", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => logAndReturn(response, false))
      .catch((err) => logAndReturn(err, false)),

  updateCard: (id, formData) =>
    instance
      .put(`/cards/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => logAndReturn(response, false))
      .catch((err) => logAndReturn(err, false)),

  deleteCard: (id) =>
    instance
      .delete(`/cards/${id}`)
      .then((response) => logAndReturn(response, false))
      .catch((err) => logAndReturn(err, false)),

  sendForm: (data) =>
    instance
      .post("/contact_form", data)
      .then((response) => logAndReturn(response, false))
      .catch((err) => logAndReturn(err, false)),
}

const logAndReturn = (response, returnData = false) => {
  console.log("API response", response)
  if (returnData) return response.data
  return response
}

export default apiClient

import React from "react"
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Card from "../common/Card"
import Divider from "../common/Divider"
import CardDetail from "./CardDetail"

const PAGINATION_NUM = 10

class CardScreen extends React.Component {
  state = {
    isDetail: false,
    card: null,
    pageNum: 0
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.pageNum !== this.state.pageNum) {
      window.scrollTo(0, 0)
    }
  }

  renderCards = (readyCards) => {
    const { screen, cart, onCardClick } = this.props

    const start = this.state.pageNum * PAGINATION_NUM
    const viewableCards = readyCards.slice(start, (start + PAGINATION_NUM))
    return viewableCards.map((item, i) => {
      let buttonText = "Remove"
      if (cart.indexOf(item.id) === -1) {
        buttonText = "Add to Cart"
      }
      return (
        <Col key={item.id} style={{ justifyContent: 'center', textAlign: 'center' }}>
          <div
            onClick={() => this.setState({ isDetail: true, card: item })}
            style={{ padding: '30px' }}
          >
            <Card alt={`${screen.screen}${i}`} card={item} />
          </div>
          <Button
            variant="outline-secondary"
            onClick={() => onCardClick(item.id)}
          >
            {buttonText}
          </Button>
          <Divider />
        </Col>
      )
    })
  }

  renderPagination = (readyCards) => {
    let active = this.state.pageNum + 1;
    let pageTotal = Math.ceil(readyCards.length / PAGINATION_NUM)
    let items = [];
    for (let number = 1; number <= pageTotal; number++) {
      if (number >= active - 3 && number <= active + 3) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => this.setState({ pageNum: number - 1})}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    if (active - 4 >= 1) items.unshift(<Pagination.Ellipsis key={'el1'} />)
    if (active + 4 <= pageTotal) items.push(<Pagination.Ellipsis key={'el2'} />)
    return <Pagination size="md" style={{ justifyContent: 'center' }}>{items}</Pagination>
  }

  render() {
    const { screen, cards, cart, onCardClick } = this.props

    if (this.state.isDetail) {
      return (
        <CardDetail
          card={this.state.card}
          backClicked={() => this.setState({ card: null, isDetail: false })}
          cart={cart}
          onCardClick={(id) => onCardClick(id)}
        />
      )
    }
    console.log(cards)
    const readyCards = screen.filteredCards(cards)
    return (
      <Container>
        <Row>
          <Col xs={4}>
            <FontAwesomeIcon
              icon="chevron-left"
              size="2x"
              onClick={() => this.props.backClicked()}
            />
          </Col>
          <Col xs={4}>
            <h2 style={{ justifyContent: 'center', textAlign: 'center' }}>{this.props.screen.screen}</h2>
          </Col>
          <Col xs={4}/>
        </Row>
        <div>{this.renderCards(readyCards)}</div>
        {this.renderPagination(readyCards)}
      </Container>
    )
  }
}

export default CardScreen

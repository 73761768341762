export const SCREENS = [
  {
    id: 1,
    screen: "Birthday",
    mainCard: (cards) =>
      cards.filter((c) => c.categories.includes("Birthday") && c.mainImage)[0],
    filteredCards: (cards) =>
      cards.filter((card) => card.categories.includes("Birthday")),
  },
  {
    id: 2,
    screen: "Sympathy",
    mainCard: (cards) =>
      cards.filter((c) => c.categories.includes("Sympathy") && c.mainImage),
    filteredCards: (cards) => 
      cards.filter((card) => card.categories.includes("Sympathy")),
  },
  {
    id: 3,
    screen: "Baby",
    mainCard: (cards) =>
      cards.filter((c) => c.categories.includes("Baby") && c.mainImage),
    filteredCards: (cards) =>
      cards.filter((card) => card.categories.includes("Baby")),
  },
  {
    id: 4,
    screen: "Wedding/Anniversary",
    mainCard: (cards) =>
      cards.filter(
        (c) => c.categories.includes("Wedding/Anniversary") && c.mainImage
      ),
    filteredCards: (cards) =>
      cards.filter((card) => card.categories.includes("Wedding/Anniversary")),
  },
  {
    id: 5,
    screen: "Blank Art Cards",
    mainCard: (cards) =>
      cards.filter(
        (c) => c.categories.includes("Blank Art Cards") && c.mainImage
      ),
    filteredCards: (cards) =>
      cards.filter((card) => card.categories.includes("Blank Art Cards")),
  },
  {
    id: 6,
    screen: "Holiday",
    mainCard: (cards) =>
      cards.filter((c) => c.categories.includes("Holiday") && c.mainImage),
    filteredCards: (cards) =>
      cards.filter((card) => card.categories.includes("Holiday")),
  },
  {
    id: 7,
    screen: "Graduation",
    mainCard: (cards) =>
      cards.filter((c) => c.categories.includes("Graduation") && c.mainImage),
    filteredCards: (cards) =>
      cards.filter((card) => card.categories.includes("Graduation")),
  },
  {
    id: 8,
    screen: "Greeting Cases",
    mainCard: (cards) =>
      cards.filter(
        (c) => c.categories.includes("Greeting Cases") && c.mainImage
      ),
    filteredCards: (cards) =>
      cards.filter((card) => card.categories.includes("Greeting Cases")),
  },
]

export const HEADER_COPY = "Card Categories"

export const COPY = [
  "Handmade in Pittsburgh with scissors, paper and glue: Collage!",
  "All designs are mounted on white 5”x7” cards with envelope included.",
  "*All cards are Blank Inside* (Some cards have short message on front.)",
  "Cards are packaged in clear cellophane.",
]

export const PRICING_COPY = [
  "\u25cf 1 for $6",
  "\u25cf 4 for $20",
  "\u25cf 10 for $45",
]

export const CUSTOM_TEXT = "Have an idea for a custom card, not on the website? Let’s talk. Custom Cards cost from $10 - $30 each, depending on time and design."

export const HEADER_COLOR = "#30C5B4"
import React from "react"
import { Nav, Row, Jumbotron, Button, Container } from "react-bootstrap";
import Success from "./common/Success"
import apiClient from "./api/api-client"
import ContactForm from "./common/ContactForm"
import SmallCard from './common/SmallCard'
import CardLoading from './common/CardLoading'


const containerStyle = { padding: '20px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
const cartRowStyle = { flexWrap: 'wrap', justifyContent: 'center', alignItems: 'flex-end' }

class Buy extends React.Component {
  state = {
    formSending: false,
    apiMessage: null,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleFormSubmit = async (data) => {
    data.card_ids = this.props.cart
    const res = await apiClient.sendForm(data)
    const msg =
      res.status === 200
        ? "Thank you! I'll be in touch!"
        : "An error occurred, please try again."
    this.setState({ apiMessage: msg, formSending: false })
  }

  renderCart() {
    const { cart, cards, onCardClick } = this.props
    if (cart.length === 0) {
      return (
        <div>
          <Row style={{ justifyContent: 'center', textAlign: 'center' }}>
            <p>There's nothing in your cart!</p>
          </Row>
          <Row style={{ justifyContent: 'center', textAlign: 'center', paddingBottom: '10px' }}>
            <Nav.Link onClick={() => this.props.onClick()}>
              Click here to view cards and add them to your cart.
            </Nav.Link>
          </Row>
        </div>
      )
    }

    let cardsInCart = []
    for (let i = 0; i < cart.length; i++) {
      for (let j = 0; j < cards.length; j++) {
        if (cart[i] === cards[j].id) {
          cardsInCart.push(
            <div key={cards[j].id} style={{ justifyContent: 'center', textAlign: 'center', padding: '0px 10px' }}>
              <SmallCard alt={cards[j].info} card={cards[j]} />
              <Button variant="outline-secondary" onClick={() => onCardClick(cards[j].id)}>Remove</Button>
            </div>
          )
          break
        }
      }
    }
    return <Row style={cartRowStyle}>{cardsInCart}</Row>
  }

  render() {
    const { formSending, apiMessage } = this.state
    if (formSending) return <CardLoading />
    if (apiMessage !== null)
      return (
        <Success
          text={apiMessage}
          onClick={() => this.setState({ apiMessage: null })}
        />
      )
    return (
      <Container style={containerStyle}>
        <Row style={containerStyle}>
          <h2>Send me a message if you'd like to buy!</h2>
        </Row>
        <Jumbotron>
          {this.renderCart()}
          <ContactForm
            onSubmit={(data) => {
              this.setState({ formSending: true })
              this.handleFormSubmit(data)
            }}
            cartIsNotEmpty={this.props.cart.length > 0}
          />
        </Jumbotron>
      </Container>
    )
  }
}

export default Buy

import React from "react"
import PropTypes from "prop-types"
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';

const containerStyle = { padding: '20px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
const Success = ({ text, onClick }) => {
  return (
    <Container style={containerStyle}>
      <Jumbotron>
        <h3>{text}</h3>
        <Button variant="outline-secondary" onClick={() => onClick()}>OK</Button>
      </Jumbotron>
    </Container>
  )
}

export default Success

Success.defaultProps = {
  text: "",
}

Success.propTypes = {
  text: PropTypes.string,
}

import React from "react"
import "./card.css"

const Card = ({ alt, card }) => {
  const info = card.info ? card.info : ""
  const classes = card.vertical ? "card-img-vert" : "card-img-horiz"
  const src = card.image ? card.image.url : ""
  return (
    <div key={card.id} className="card-img-container">
      <img src={src} alt={alt} className={classes} />
      <div className="card-img-name-text">{card.name}</div>
      <div className="card-img-name-info">{info}</div>
    </div>
  )
}

export default Card

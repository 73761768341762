import React from "react"
import Home from "./Home"
import CardScreen from "./CardScreen"
import { SCREENS } from "../common/Constants"

class Cards extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  state = {
    currentScreen: "home",
    cardClickedId: null
  }

  renderHomeOrScreens = () => {
    const { currentScreen } = this.state
    const { cards } = this.props
    if (currentScreen === "home") {
      return (
        <Home
          onRefreshClick={() => {
            this.setState({ loading: true })
            this.props.retrieveCards()
          }}
          onScreenClick={(screen, cardId) => this.setState({ currentScreen: screen, cardClickedId: cardId })}
          cards={cards}
        />
      )
    }
    const selectedScreen = SCREENS.filter(
      (item) => item.screen === currentScreen
    )[0]
    const mainCardIdx = cards.findIndex((c) => c.id === this.state.cardClickedId)
    return (
      <CardScreen
        screen={selectedScreen}
        backClicked={() => this.setState({ currentScreen: "home" })}
        cards={
          [
            cards[mainCardIdx],
            ...cards.slice(0, mainCardIdx),
            ...cards.slice(mainCardIdx + 1),
          ]
        }
        cart={this.props.cart}
        onCardClick={(id) => this.props.onCardClick(id)}
      />
    )
  }

  render() {
    return (
      <div style={{ padding: '20px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        {this.renderHomeOrScreens()}
      </div>
    )
  }
}

export default Cards

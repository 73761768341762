import React from "react"
import './cardLoading.css'

const style = { width: '100%', textAlign: 'center', paddingTop: '20px', display: 'block' }
const CardLoading = () => {
  return (
    <div style={style}>
      {/* <FontAwesomeIcon spin icon="cut" size="6x"/> */}
      <img className="loading-img-vert rotate" src="scissors.png" alt="Scissors loading" />
      <h3 style={{ paddingTop: '20px' }}>Loading...</h3>
    </div>
  )
}

export default CardLoading

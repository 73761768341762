import React from "react"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CUSTOM_TEXT } from "../common/Constants"

const MESSAGE_ERROR = "Please provide a quick message since you have no cards in your cart"
const ALL_GOOD = "Looks good!"
const EMAIL_ERROR = "Please provide your email address"
const NAME_ERROR = "Please provide your name"

class ContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      email: "",
      msg: "",
      submitPressed: false,
      showTextarea: false
    }
  }

  formChecksOut = () => {
    const { name, email, msg } = this.state
    const { cartIsNotEmpty } = this.props
    const valid = (
      this.nameValid(name) &&
      this.emailValid(email) &&
      this.cartOrMsg(cartIsNotEmpty, msg)
    )
    return valid
  }

  nameValid = () => {
    return this.state.name !== ""
  }

  emailValid = () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return emailRegex.test(this.state.email)
  }

  cartOrMsg = () => {
    const { msg } = this.state
    const { cartIsNotEmpty } = this.props
    return cartIsNotEmpty || (msg !== "" && msg.length > 10)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ submitPressed: true })
    if (this.formChecksOut()) {
      this.props.onSubmit(this.state)
    }
    return false
  }

  maybeShowTextarea = () => {
    const { submitPressed, showTextarea, msg } = this.state;
    const { cartIsNotEmpty } = this.props
    if (showTextarea) {
      return (
        <div>
          <Form.Group controlId="formMsg">
            <Form.Label>Enter your message</Form.Label>
            <Form.Control
              required={!cartIsNotEmpty}
              as="textarea"
              placeholder=""
              value={msg}
              onChange={(e) => this.setState({ msg: e.target.value })}
              rows="8"
              isValid={submitPressed && this.cartOrMsg()}
              isInvalid={submitPressed && !this.cartOrMsg()}
            />
            {!cartIsNotEmpty && <Form.Control.Feedback>Looks good!</Form.Control.Feedback>}
            {!cartIsNotEmpty && <Form.Control.Feedback type="invalid">{MESSAGE_ERROR}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Label>*{CUSTOM_TEXT}</Form.Label>
        </div>
      )
    }
    return (
      <div>
        <p>
          Click here if you’d like to leave me a message along with your name and email
        </p>
        <Button
          variant="outline-dark"
          type="button"
          onClick={() => this.setState({ showTextarea: true })}
        >
          Show Form
        </Button>
      </div>
    )
  }

  render() {
    const { submitPressed } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} noValidate validated={this.formChecksOut()}>
        <Row style={{ paddingBottom: '30px', flexWrap: 'wrap' }}>
          <Col md={6} sm={12}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="name"
                placeholder="Enter name"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                isValid={submitPressed && this.nameValid()}
                isInvalid={submitPressed && !this.nameValid()}
              />
              <Form.Control.Feedback>{ALL_GOOD}</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{NAME_ERROR}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} sm={12}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter email"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                isValid={submitPressed && this.emailValid()}
                isInvalid={submitPressed && !this.emailValid()}
              />
              <Form.Control.Feedback>{ALL_GOOD}</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{EMAIL_ERROR}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {this.maybeShowTextarea()}
        <Form.Group style={{ paddingTop: '30px'}}>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form.Group>
      </Form>
    )
  }
}

export default ContactForm

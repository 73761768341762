import React from "react"

import "./cardsDelete.css"

class CardsDelete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.card.id,
      name: props.card.name,
    }
  }

  render() {
    const { onScreenChange, deleteCard } = this.props
    return (
      <div className="delete-container">
        <div className="delete-name-text">Card Name: {this.state.name}</div>
        <div className="delete-text">
          Are you sure you want to delete this card?
        </div>
        <div className="delete-btns-container">
          <div className="delete-button">
            <button onClick={() => onScreenChange("index", null)}>
              Go Back
            </button>
          </div>
          <div className="delete-button">
            <button onClick={() => deleteCard(this.state.id)}>
              Confirm Delete
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default CardsDelete

CardsDelete.defaultProps = {
  id: null,
  name: "",
}

import React from "react"
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { COPY, PRICING_COPY, CUSTOM_TEXT } from "./common/Constants"

const containerStyle = { padding: '20px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
const topBottomStyle = { paddingTop: '20px', paddingBottom: '20px' }

const About = ({ onClick }) => {
  window.scrollTo(0, 0)
  const renderCopy = () => {
    return COPY.map((item) => (
      <Row key={item.length} style={{ justifyContent: 'center' }}>
        <h5>
          {item}
        </h5>
      </Row>
    ))
  }

  const renderPricing = () => {
    return PRICING_COPY.map((item, i) => {
      return (
        <h5 key={item.length}>
          {item}
        </h5>
      )
    })
  }

  return (
    <Container style={containerStyle}>
      <h2>About the Cards</h2>
      <Col style={topBottomStyle}>{renderCopy()}</Col>
      <Jumbotron style={{ backgroundColor: '#D3FFFA'}}>
        <h3>Pricing</h3>
        {renderPricing()}
        <Row style={topBottomStyle}>
          <Col />
          <Col xs={8}>
            <h5>{CUSTOM_TEXT}</h5>
          </Col>
          <Col />
        </Row>
      </Jumbotron>
      <Button variant="outline-secondary" onClick={() => onClick()}>
        Buy Cards
      </Button>
    </Container>
  )
}

export default About

import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { SCREENS, HEADER_COPY } from "../common/Constants"
import CardLoading from '../common/CardLoading'

import './home.css'

const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)
const mainImg = (mainCard) => {
  if (mainCard === undefined) return null
  return get(["image", "url"], mainCard)
}

class Home extends React.Component {
  renderPictures = () => {
    const { onScreenClick, cards } = this.props
    if (cards.length === 0 || cards.length === undefined) {
      return <CardLoading />
    }

    return SCREENS.map((item) => {
      const mainCard = cards.filter(
        (c) => c.categories.includes(item.screen) && c.main_image
      )[0]
      const isVertical = mainCard === undefined ? false : mainCard.vertical
      return (
        <Col
          key={item.id}
          style={{ justifyContent: 'center', textAlign: 'center' }}
          md={6}
          sm={12}
        >
          <h2>{item.screen}</h2>
          <img
            src={mainImg(mainCard)}
            alt={item.screen}
            onClick={() => onScreenClick(item.screen, mainCard.id)}
            className={`${
              isVertical ? "home-card-img-vert" : "home-card-img-horiz"
              } skewedshadow`}
          />
        </Col>
      )
    })
  }

  render() {
    return (
      <Container style={{ margin: '0px' }}>
        <Col style={{ justifyContent: 'center', textAlign: 'center' }}>
          <h1>{HEADER_COPY}</h1>
          <Button
            variant="outline-secondary"
            onClick={() => this.props.onRefreshClick()}
          >
            Refresh
          </Button>
        </Col>
        <Row style={{ flexwrap: 'wrap'}}>{this.renderPictures()}</Row>
      </Container>
    )
  }
}

export default Home

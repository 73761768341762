import React from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Card from "../common/Card"

const CardDetail = ({ card, backClicked, onCardClick, cart }) => {
  const newName = card.name
  const newCard = { ...card }
  delete newCard.name

  let buttonText = "Remove"
  if (cart.indexOf(newCard.id) === -1) {
    buttonText = "Add to Cart"
  }
  return (
    <Container style={{ justifyContent: 'center', textAlign: 'center' }}>
      <Row>
        <Col xs={2}>
          <FontAwesomeIcon
            icon="chevron-left"
            size="2x"
            onClick={() => backClicked()}
          />
        </Col>
        <Col xs={8}>
          <h2 style={{ justifyContent: 'center', textAlign: 'center' }}>
            {newName}
          </h2>
        </Col>
        <Col xs={2} />
      </Row>
      <Card alt={newCard.info} card={newCard} />
      <Button
        variant="outline-secondary"
        onClick={() => onCardClick(newCard.id)}
      >
        {buttonText}
      </Button>
      <div style={{ height: "15vh" }} />
    </Container>
  )
}

export default CardDetail

import React from "react"

const SmallCard = ({ alt, card }) => {
  const src = card.image ? card.image.url : ""
  return (
    <div key={card.id}>
      <img src={src} alt={alt} width="150vw"/>
      {/* <div>{`${card.name.substring(0,10)}...`}</div> */}
    </div>
  )
}

export default SmallCard

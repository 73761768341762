import React from "react"
import { SCREENS } from "../common/Constants"

import "./filters.css" 

const Filters = ({ categoryFilters, categoryClicked }) => {
  const isSelected = (item) => categoryFilters.includes(item.toLowerCase())
  return (
    <>
      <div className="header-small">FILTER OUT CATEGORIES</div>
      <div className="filters-container">
        {SCREENS.map((item) => {
          const selected = isSelected(item.screen)
          const btnText = selected ? `No ${item.screen}`.substring(0, 18) : item.screen
          const classname = selected ? "filter-selected" : "filter-not-selected"
          return (
            <div key={item.id} className="filter-btn-wrapper">
              <button
                onClick={() => categoryClicked(item.screen)}
                className={classname}
              >
                {btnText}
              </button>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Filters

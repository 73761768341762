import React from "react"
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Card from '../common/Card'
import Divider from "../common/Divider"

const containerStyle = { padding: '20px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }

const Cart = ({ cart, cards, onCardClick }) => {
  window.scrollTo(0, 0)
  const renderCards = () => {
    let cardsInCart = []
    for (let i = 0; i < cart.length; i++) {
      for (let j = 0; j < cards.length; j++) {
        if (cart[i] === cards[j].id) {
          cardsInCart.push(
            <div key={cards[j].id}>
              <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                <Card alt={cards[j].info} card={cards[j]} />
                <Button onClick={() => onCardClick(cards[j].id)}>Remove</Button>
              </div>
              {i !== cart.length - 1 && <Divider />}
            </div>
          )
          break
        }
      }
    }
    return cardsInCart
  }
  return (
    <Container style={{ padding: '20px 0px' }}>
      <Row style={containerStyle}>
        <h2>My Cart</h2>
      </Row >
      {renderCards()}
    </Container>
  )
}

export default Cart

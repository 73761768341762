import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { HEADER_COLOR } from './common/Constants'

const items = [
  { id: 1, name: "home", icon: "book-open", text: "Cards" },
  { id: 2, name: "about", icon: "info-circle", text: "About" },
  // { id: 3, name: "cart", icon: "shopping-cart", text: "Cart" },
  {
    id: 4,
    name: "buy",
    icon: "comment-dollar",
    text: "My Cart",
  },
  { id: 5, name: "upload", icon: "upload", text: "Upload" },
]

const iconStyle = { width: '100%', textAlign: 'center' }
const activeStyle = { color: '#000000' }
const inactiveStyle = { color: 'hsla(0,0%,10%,.5)' }

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
  }
  mapMenuItems = () => {
    const { currentScreen, cart } = this.props
    return items.map((item) => {
      let text = item.text
      if (item.name === 'cart') text = `${text} (${cart.length})`
      return (
        <Nav.Item
          key={item.id}
          onClick={() => {
            this.setState({ expanded: false })
            this.props.onClick(item.name)
          }}
        >
          <Col style={currentScreen === item.name ? activeStyle : inactiveStyle}>
            <Row><FontAwesomeIcon icon={item.icon} size="2x" style={iconStyle}/></Row>
            <Row>
              <div style={iconStyle}>
                {text}
              </div>
            </Row>
          </Col>
        </Nav.Item>
      )
    })
  }

  render() {
    return (
      <Navbar
        style={{ backgroundColor: HEADER_COLOR }}
        sticky="top"
        expand='sm'
        expanded={this.state.expanded}
      >
        <Navbar.Brand
          onClick={() => {
            this.setState({ expanded: false })
            this.props.onClick('home')
          }}
          style={{ fontSize: '24px'}}
        >
          Art Cards PGH
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => this.setState(prevState => ({ expanded: !prevState.expanded }))}
        />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          {this.mapMenuItems()}
        </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Menu
